<script>

import AdvancedTable from "../../../components/elements/advanced-table.vue";
import {mapGetters} from "vuex";

export default {
    components: {
        AdvancedTable,
    },

    computed: {
        ...mapGetters({
            locale: 'trans/locale'
        }),

        fields: function () {
            return [
                {
                    key: "name",
                    label: this.$t('projects::implements.columns.name').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "implement_parts",
                    label: this.$tc('projects::implement_parts.implement_part', 1).ucFirst(),
                    formatter: (value) => value?.map(part => part.name)?.join(' | '),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "realized_at",
                    label: this.$t('projects::implements.columns.realized_at').ucFirst(),
                    formatter: (value) => value && value.printDate(this.locale),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: '',
                    tdClass: '',
                    sortable: false
                },
            ];
        },
    },

    data() {
        return {};
    },

    methods: {
        getDate: function (str) {
            return str.split(',')[0]
        },

        getTime: function (str) {
            return str.split(',')[1]
        },
    },

    mounted() {
        this.$store.dispatch('app/title', {key: 'projects::implements.implement', count: 2})
    },

    unmounted() {
        this.$store.dispatch('app/title', '')
    }
};
</script>

<template>
    <advanced-table
        :caption="$tc('projects::implements.implement', 2).ucFirst()"
        :create-link="{ name: 'my-implement-create' }"
        :current-page="1"
        :edit-link="{ name: 'my-implement-edit' }"
        :empty-filtered-text="$t('base.empty_filtered').ucFirst()"
        :empty-text="$t('base.empty_table').ucFirst()"
        :fields="fields"
        :queries="{ with: ['implementParts'] }"
        :per-page="10"
        borderless
        deletable
        editable
        hover
        moduleNamespace="implement"
        action="own"
        outlined
        permission="implements"
        primary-key="id"
        responsive="sm"
        searchable
        show-empty
        sort-by="id"
        sort-direction="asc"
        sort-icon-left
    >
        <template #implementers="{ value }">
            <div class="avatar-group">
                <div v-for="(user, index) in value" :key="index" class="avatar-group-item">
                    <a v-if="user.user.image && user.user.image.path" href="javascript: void(0);" class="d-inline-block">
                        <img :src="user.user.image.path"
                             :alt="user.user.name"
                             :title="user.user.name"
                             class="rounded-circle avatar-sm">
                    </a>
                    <div v-else class="avatar-sm cursor-pointer">
                        <span :title="user.user.name" class="avatar-title rounded-circle bg-primary text-white font-size-16 user-select-none"> {{ user.user.name.firstLetters() }} </span>
                    </div>
                </div>
            </div>
        </template>
    </advanced-table>
</template>
